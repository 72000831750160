<template>
    <!--编辑页与详情页相同-->
    <div class="cashRefundDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form @submit.native.prevent :inline="true" size="small" label-width="85px">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="机构">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="交易流水号">
                                <el-input v-model="form.tradeBar" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="交易时间">
                                <el-date-picker v-model="form.tradeTime" value-format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="交易收银员">
                                <el-input v-model="form.cashier" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="状态">
                                <el-input :value="form.settleStatus | settleStatus" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="退款类型">
                                <el-input v-model="form.payType" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="退货日期">
                                <el-date-picker v-model="form.stockChangeTime" value-format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="退货收银员">
                                <el-input v-model="form.tradeCashier" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="会员">
                                <el-input v-model="form.memberName" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="应退金额">
                                <el-input :value="form.needRefund | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="优惠金额">
                                <el-input :value="form.refundPreference | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="实退金额">
                                <el-input :value="form.refundMoney | money" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="140" />
                <el-table-column prop="sku.bars" label="条码" width="120" />
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="100" />
                <el-table-column label="购买数量" width="120" prop="tradeCountShow" />
                <el-table-column label="退货数量" width="120" prop="counts" />
                <el-table-column label="原单价" width="120" prop="oriPrices" />
                <el-table-column label="会员单价" width="120" prop="prices" />
                <el-table-column label="优惠扣款" width="130" prop="favourPrices" />
                <el-table-column label="应退金额" width="130" prop="needPrices" />
                <el-table-column label="实退金额" width="130" prop="realPrices" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import MoneyUtils from '../../../../js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'CashRefundDetail',
    props: ['form'],
    data() {
        return {
            url: {
                queryGoods: '/goods/goods/list_c',
            },
            meta: {
                types: [],
            },
            codes: [],
            tableData: [],
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleQuery: function () {
            const _this = this;
            UrlUtils.QueryGoodsInfoByCodes(_this, _this.url.queryGoods, { codes: _this.form.goodsCode }, (data) => {
                data.forEach((v, i) => {
                    v.tradeCountShow =
                        '购：' + this.form.tradeCounts[i] + '（赠：' + this.form.tradeGiftCounts[i] + '）';
                    v.counts = this.form.counts[i];
                    v.oriPrices = MoneyUtils.moneyToYuan(this.form.oriPrices[i]);
                    v.prices = MoneyUtils.moneyToYuan(this.form.prices[i]);
                    v.needPrices = MoneyUtils.moneyToYuan(this.form.needPrices[i]);
                    v.favourPrices = MoneyUtils.moneyToYuan(this.form.favourPrices[i]);
                    v.realPrices = MoneyUtils.moneyToYuan(this.form.realPrices[i]);
                });
                _this.tableData = data;
            });
        },
    },
    filters: {
        settleStatus(settleStatus) {
            if (typeof settleStatus == 'undefined') {
                return '';
            }
            if (settleStatus == '0') {
                return '待结算';
            }
            if (settleStatus == '1') {
                return '已结算';
            }
        },
        countMoney(counts, prices) {
            if (typeof counts == 'undefined') {
                return '';
            } else if (typeof prices == 'undefined') {
                return '';
            } else {
                return (counts * prices).toFixed(2);
            }
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
